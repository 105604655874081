<template>
    <f7-page class="no-data-page">
        <f7-navbar class="no-data-page__navbar">
            <f7-nav-left></f7-nav-left>
            <f7-nav-title></f7-nav-title>
            <f7-nav-right>
                <a href="#" class="link icon-only svg-filter-AAADB3" @click="openAlertToTelegram">
                    <i class="icon telegram-svg"></i>
                </a>
            </f7-nav-right>
        </f7-navbar>
        <template v-if="criticalErrorResponse">
            <template></template>
            <no-agents-with-ranges-error
                v-if="criticalErrorResponse.code === AccountError.AgentsUnavailableForAmount && canShowNoAgentsWithRangesError"
                :ranges="criticalErrorResponse.data.ranges"
            />
            <no-agents-error v-else-if="criticalErrorResponse.code === AccountError.AgentNotFound"/>
            <standart-error v-else/>
        </template>
        <standart-error v-else/>
    </f7-page>
</template>

<script setup lang="ts">
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import ResponseJson from "@models/responses/ResponseJson";
import StandartError from "@/targets/light/views/auth-error/components/StandartError.vue";
import NoAgentsWithRangesError from "@/targets/light/views/auth-error/components/NoAgentsWithRangesError.vue";
import NoAgentsError from "@/targets/light/views/auth-error/components/NoAgentsError.vue";
import {computed} from "vue";
import {f7} from "framework7-vue";
import {getDevice} from "framework7/shared/get-device";
import i18n from "@/langs/i18n";
import {AccountError} from "@enums/ServerErrorCodes";

const {t} = i18n.global;
const appController = AppController.getInstance();
const criticalErrorResponse: ResponseJson<any> | null = appController.criticalErrorResponse;
const canShowNoAgentsWithRangesError = computed(() => {
    if (!criticalErrorResponse) return false;
    if (!criticalErrorResponse.data) return false;
    if (!criticalErrorResponse.data.ranges) return false;
    return true;
});

function reloadPage() {
    window.location.reload();
}

const openAlertToTelegram = () => {
    f7.dialog.create({
        title: t("g.alerts.to-telegram.title"),
        text: t("g.alerts.to-telegram.text"),
        cssClass: "simple",
        buttons: [
            {
                text: t("g.no")
            },
            {
                text: t("g.yes"),
                onClick: async () => {
                    const url = "https://t.me/BroMoney_robot";
                    if (getDevice().cordova) {
                        // @ts-ignore
                        window.cordova.InAppBrowser.open(url, '_system');
                    } else {
                        try {
                            if (getDevice().ios) {
                                // @ts-ignore
                                window.webkit.messageHandlers.iosListener.postMessage(JSON.stringify({
                                    "deeplink": "https://t.me/BroMoney_robot"
                                }));
                            } else if (getDevice().android) {
                                // @ts-ignore
                                window.webInterface.onMessage(JSON.stringify({
                                    "deeplink": "https://t.me/BroMoney_robot"
                                }));
                            } else {
                                window.open(url, "_target");
                            }
                        } catch (e) {
                            window.open('https://t.me/BroMoney_robot', "_target");
                        }
                    }
                }
            }
        ]
    }).open();
}
</script>

<style lang="scss">
.no-data-page {
    &__navbar {
        .navbar-bg {
            --f7-navbar-bg-color: var(--white1);

            &:after {
                content: none;
            }
        }
    }

    --f7-page-bg-color: var(--white1);

    .info-block {
        margin-top: calc(56px - 44px) !important;
    }
}
</style>
